<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-center justify-space-between"
      style="min-height: 36px"
    >
      <table-name
        :table-number="tableNumber"
        :table-prefix="tablePrefix"
        :table-suffix="tableSuffix"
        class="text-xs mb-1"
      />

      <div
        v-if="addedCustomerTags.length > 0"
        class="d-flex align-center"
      >
        <v-icon
          left
          small
        >
          {{ icons.mdiPound }}
        </v-icon>

        <div style="max-width: 240px">
          <v-chip-group class="customer-tags-cap-slide">
            <v-chip
              v-for="(customerTag) in addedCustomerTags"
              :key="`${tablePrefix}${tableNumber}${tableSuffix}-customerTag${customerTag.id}`"
              small
            >
              {{ customerTag.attributes.name }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>

    <v-card
      class="elevation-4 position-relative"
      min-height="236.5"
      :ripple="false"
      @click="$emit('clicked')"
    >
      <table-timer
        :start-at="startAt"
        :expire-at="expireAt"
        class="py-4 px-3"
      />
      <v-divider></v-divider>

      <v-card-text
        class="d-flex flex-grow-1 flex-column justify-space-between"
      >
        <div class="d-flex align-center justify-space-between">
          <div>
            <v-badge
              :content="customerCount"
              inline
            >
              <v-icon
                large
              >
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-badge>
          </div>

          <div class="d-flex">
            <sup>
              <v-icon>
                {{ icons.mdiCurrencyJpy }}
              </v-icon>
            </sup>
            <span
              class="text-2xl font-weight-semibold"
            >
              {{ total.toLocaleString() }}
            </span>
          </div>
        </div>

        <div
          class="d-flex align-center"
          style="min-height: 36px"
        >
          <v-icon
            left
            small
          >
            {{ icons.mdiVectorLink }}
          </v-icon>
          <v-chip-group
            v-if="referringHistories.length > 0"
            column
          >
            <span
              v-for="referringHistory in referringHistories"
              :key="`referringHistory${referringHistory.id}`"
            >
              {{ referringHistory.attributes.user.data.attributes.name }}
              <v-chip small>
                {{ referringHistory.attributes.referral.data.attributes.name }}
              </v-chip>
            </span>
          </v-chip-group>

          <span v-else>---</span>
        </div>

        <nomination-display
          :nomination-histories="currentNominations"
        />
      </v-card-text>

      <v-menu
        top
        left
      >
        <template #activator="{ on: menuOn, attrs: menuAttrs }">
          <v-btn
            icon
            absolute
            style="bottom: 0; right: 0;"
            v-bind="menuAttrs"
            v-on="menuOn"
          >
            <v-icon
              small
              v-text="icons.mdiDotsVertical"
            />
          </v-btn>
        </template>

        <template #default="menu">
          <v-list
            dense
            outlined
          >
            <v-subheader>操作</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <span class="mr-4">移動</span>
                  <div @click.stop>
                    <v-select
                      v-model="moveTableParams.tableNumber"
                      :items="tablesToMove"
                      :prepend-icon="icons.mdiArrowRightBold"
                      dense
                      hide-details
                      @change="onMoveTableSelect($event, menu)"
                    />
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-card>
  </div>
</template>

<script>
import { reactive, computed, inject } from '@vue/composition-api'
import { map } from 'lodash'
import {
  mdiAccountOutline,
  mdiCurrencyJpy,
  mdiVectorLink,
  mdiPound,
  mdiDotsVertical,
  mdiArrowRightBold,
} from '@mdi/js'
import TableName from '@/views/components/table/TableName.vue'
import TableTimer from '@/views/components/table/TableTimer.vue'
import NominationDisplay from '@/views/components/table/NominationDisplay.vue'

export default {
  components: {
    TableName,
    TableTimer,
    NominationDisplay,
  },
  props: {
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    tableNumber: {
      type: [String, Number],
      required: true,
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
    subtotal: {
      type: [Number, String],
      required: true,
    },
    total: {
      type: [Number, String],
      required: true,
    },
    currentNominations: {
      // NOTE: 中身はNominationだけのvending_history
      type: Array,
      default: () => [],
    },
    referringHistories: {
      type: Array,
      default: () => [],
    },
    customerTableTaggings: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const halls = inject('halls', [])

    const moveTableParams = reactive({
      tableNumber: null,
      hallId: null,
    })

    const addedCustomerTags = computed(() => {
      if (props.customerTableTaggings.length < 1) return []

      return map(props.customerTableTaggings, 'attributes.customerTag.data')
    })

    const tableName = computed(() => {
      return [props.tablePrefix, props.tableNumber, props.tableSuffix].join('')
    })

    const sortedHalls = computed(() => {
      return halls.value.slice().sort((a, b) => {
        return a.attributes.position - b.attributes.position
      })
    })

    const tablesToMove = computed(() => {
      return sortedHalls.value.flatMap(hall => {
        // 現在進行中のテーブル番号を取得
        const ongoingTableNumbers = hall.attributes.tables.data.map(table => table.attributes.tableNumber)

        // テーブルリスト生成
        const tables = Array.from({ length: hall.attributes.tableCount }, (_, i) => {
          const tableNumber = i + 1

          // 現在進行中のテーブルを除外
          if (ongoingTableNumbers.includes(tableNumber)) return null

          return {
            text: [hall.attributes.tablePrefix, tableNumber, hall.attributes.tableSuffix].join(''),
            value: `${hall.id}-${tableNumber}`,
          }
        }).filter(Boolean) // null を除外

        return [{ header: hall.attributes.name }, ...tables]
      })
    })

    const onMoveTableSelect = (value, menu) => {
      // eslint-disable-next-line no-param-reassign
      const callback = () => (menu.value = false)

      const [hallId, tableNumber] = value.split('-')
      moveTableParams.hallId = Number(hallId)
      moveTableParams.tableNumber = Number(tableNumber)

      emit('move-table', { hallId, tableNumber, callback })
    }

    return {
      moveTableParams,
      halls,

      // computed
      addedCustomerTags,
      tableName,
      tablesToMove,

      // methods
      onMoveTableSelect,

      icons: {
        mdiAccountOutline,
        mdiCurrencyJpy,
        mdiVectorLink,
        mdiPound,
        mdiDotsVertical,
        mdiArrowRightBold,
      },
    }
  },
}
</script>

<style lang="scss">
.customer-tags-cap-slide {
  .v-slide-group__prev--disabled, .v-slide-group__next--disabled {
    display: none;
  }
}
</style>
