<template>
  <div
    v-click-outside="{
      handler: close,
      closeConditional,
      include: include,
    }"
  >
    <v-navigation-drawer
      :key="`table-create-drawer-${tableNumber}`"
      ref="drawer"
      :value="isVisible"
      app
      right
      temporary
      stateless
      width="400"
      class="table-create-drawer"
    >
      <!-- NOTE v-app-barのv-ifは設定しないとHall.vueのタブ移動でなぜか一瞬表示されてしまう問題対策 -->
      <v-app-bar
        v-if="isVisible"
        class="mb-4"
        outlined
        fixed
        flat
        style="z-index: 10"
      >
        <v-app-bar-nav-icon>
          <v-btn
            fab
            small
            :ripple="false"
            @click="close"
          >
            <v-icon>
              {{ icons.mdiDotsGrid }}
            </v-icon>
          </v-btn>
        </v-app-bar-nav-icon>

        <v-app-bar-title>
          <template #default>
            <table-name
              :table-number="tableNumber"
              :table-prefix="tablePrefix"
              :table-suffix="tableSuffix"
            />
          </template>
        </v-app-bar-title>
      </v-app-bar>

      <v-card
        elevation="0"
        tile
      >
        <v-card-text class="pb-16">
          <v-card
            elevation="0"
            class="mb-7 mt-10"
          >
            <v-card-subtitle
              class="text-sm pa-0 pt-3 mb-5"
            >
              客数
            </v-card-subtitle>
            <div
              class="d-flex align-center flex-wrap px-1 customer-counter-input-wrapper"
            >
              <number-counter
                v-if="isVisible"
                :number="customerCount"
                :min="1"
                :open-by-default="isVisible"
                class="customer-counter-input"
                @change="customerCount = $event"
              >
                <template #prepend-number>
                  <v-icon>
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                </template>
              </number-counter>
            </div>

            <customer-table-tagging
              @updated="setCustomerTableTaggings"
            />
          </v-card>

          <!-- リファラル start -->
          <v-card
            v-if="referrals.length > 0 && users.length > 0"
            elevation="0"
          >
            <v-card-subtitle
              class="text-sm pa-0 d-flex justify-space-between align-center pb-2 referral-subtitle"
            >
              {{ currentClub.referralAlias }}
              <v-btn
                :ripple="false"
                icon
                color="primary"
                class="referral-add-btn"
                @click="addReferringHistoryTemp({})"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-card-subtitle>

            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <transition-group
              name="fadeUp"
              class="d-flex"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-column-reverse'"
            >
              <div
                v-for="(referringHistoryTemp, referringHistoryTempIdx) in referringHistoriesTemp"
                :key="`referringHistoriesTemp[${referringHistoryTempIdx}]`"
                style="animation-duration: 0.3s"
              >
                <referral-card
                  :id="`referral-card-${referringHistoryTempIdx}`"
                  :key="`referral-card-${referringHistoryTempIdx}/${referringHistoriesTemp.length}`"
                  :value="referringHistoryTemp"
                  :referrals="referrals"
                  :users="users"
                  :referring-history-temp-idx="referringHistoryTempIdx"
                  class="mt-3 mb-8"
                  @update:referralId="referringHistoryTemp.referralId = $event"
                  @update:userId="referringHistoryTemp.userId = $event"
                  @remove="removeReferringHistoryTemp($event)"
                />
              </div>
            </transition-group>
          </v-card>
          <!-- リファラル end -->

          <!-- ノミネーション start -->
          <v-card
            v-if="nominations.length > 0 && users.length > 0"
            elevation="0"
            class="my-10 nomination-order-cards"
          >
            <v-card-subtitle
              class="text-sm pa-0 d-flex justify-space-between align-center pb-2 nomination-subtitle"
            >
              {{ currentClub.nominationAlias }}
              <v-btn
                icon
                :ripple="false"
                color="primary"
                class="nomination-add-btn"
                @click="addVendingHistoryTemp({
                  vendibleId: nominations[0].id,
                  vendibleType: 'Nomination',
                  sellingPrice: nominations[0].attributes.sellingPrice,
                })"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-card-subtitle>

            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <transition-group
              name="fadeUp"
              class="d-flex"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-column-reverse'"
            >
              <div
                v-for="(nominationHistoryTemp, nominationHistoryTempIdx) in nominationHistoriesTemp"
                :key="`nominationHistoriesTemp[${nominationHistoryTempIdx}]`"
                style="animation-duration: 0.3s"
              >
                <order-card
                  :id="`nomination-card-${nominationHistoryTempIdx}`"
                  :key="`nomination-order-card-${nominationHistoryTempIdx}/${nominationHistoriesTemp.length}`"
                  v-model="nominationHistoryTemp.value"
                  :vendibles="nominations"
                  :users="users"
                  :size="nominationHistoryTemp.size"
                  class="mt-3 mb-8"
                  @update:vendibleId="nominationHistoryTemp.value.vendibleId = $event"
                  @update:vendibleType="nominationHistoryTemp.value.vendibleType = $event"
                  @update:sellingPrice="nominationHistoryTemp.value.sellingPrice = $event"
                  @update:taxCharge="nominationHistoryTemp.value.taxCharge = $event"
                  @update:vendingContributionsAttributes="nominationHistoryTemp.value.vendingContributionsAttributes = $event"
                  @update:isArbitraryVendingContribution="nominationHistoryTemp.isArbitraryVendingContribution = $event"
                  @update:vendibleRecipientsAttributes="nominationHistoryTemp.value.vendibleRecipientsAttributes = $event"
                  @update:size="nominationHistoryTemp.size = $event"
                  @remove="removeVendingHistoryTempByValue(nominationHistoryTemp.value)"
                />
              </div>
            </transition-group>
          </v-card>
          <!-- ノミネーション end -->

          <!-- コース start -->
          <v-card
            v-if="courses.length > 0"
            elevation="0"
            class="my-10 course-order-cards"
          >
            <v-card-subtitle
              class="text-sm pa-0 d-flex justify-space-between align-center pb-2 course-subtitle"
            >
              {{ currentClub.courseAlias }}
              <v-btn
                icon
                :ripple="false"
                color="primary"
                class="course-add-btn"
                @click="addVendingHistoryTemp({ vendibleType: 'Course' })"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-card-subtitle>

            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <transition-group
              name="fadeUp"
              class="d-flex"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-column-reverse'"
            >
              <div
                v-for="(courseHistoryTemp, courseHistoryTempIdx) in courseHistoriesTemp"
                :key="`courseHistoriesTemp[${courseHistoryTempIdx}]`"
                style="animation-duration: 0.3s"
              >
                <order-card
                  :id="`course-card-${courseHistoryTempIdx}`"
                  :key="`course-order-card-${courseHistoryTempIdx}/${courseHistoriesTemp.length}`"
                  v-model="courseHistoryTemp.value"
                  :vendibles="courses"
                  :users="users"
                  :size="courseHistoryTemp.size"
                  class="mt-3 mb-8"
                  @update:vendibleId="courseHistoryTemp.value.vendibleId = $event"
                  @update:vendibleType="courseHistoryTemp.value.vendibleType = $event"
                  @update:sellingPrice="courseHistoryTemp.value.sellingPrice = $event"
                  @update:taxCharge="courseHistoryTemp.value.taxCharge = $event"
                  @update:vendingContributionsAttributes="courseHistoryTemp.value.vendingContributionsAttributes = $event"
                  @update:isArbitraryVendingContribution="courseHistoryTemp.isArbitraryVendingContribution = $event"
                  @update:size="courseHistoryTemp.size = $event"
                  @remove="removeVendingHistoryTempByValue(courseHistoryTemp.value)"
                />
              </div>
            </transition-group>
          </v-card>
          <!-- コース end -->

          <!-- Item start -->
          <v-card
            v-if="items.length > 0"
            elevation="0"
            class="my-10"
          >
            <v-card-subtitle
              class="text-sm pa-0 d-flex justify-space-between align-center pb-2"
            >
              チャージ
              <v-btn
                icon
                :ripple="false"
                color="primary"
                @click="addVendingHistoryTemp({ vendibleType: 'Item' })"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-card-subtitle>

            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <transition-group
              name="fadeUp"
              class="d-flex"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-column-reverse'"
            >
              <div
                v-for="(itemHistoryTemp, itemHistoryTempIdx) in itemHistoriesTemp"
                :key="`itemHistoriesTemp[${itemHistoryTempIdx}]`"
                style="animation-duration: 0.3s"
              >
                <order-card
                  :key="`item-order-card-${itemHistoryTempIdx}/${itemHistoriesTemp.length}`"
                  v-model="itemHistoryTemp.value"
                  :vendibles="items"
                  :users="users"
                  :size="itemHistoryTemp.size"
                  class="mt-3 mb-8"
                  @update:vendibleId="itemHistoryTemp.value.vendibleId = $event"
                  @update:vendibleType="itemHistoryTemp.value.vendibleType = $event"
                  @update:sellingPrice="itemHistoryTemp.value.sellingPrice = $event"
                  @update:taxCharge="itemHistoryTemp.value.taxCharge = $event"
                  @update:vendingContributionsAttributes="itemHistoryTemp.value.vendingContributionsAttributes = $event"
                  @update:isArbitraryVendingContribution="itemHistoryTemp.isArbitraryVendingContribution = $event"
                  @update:vendibleRecipientsAttributes="itemHistoryTemp.value.vendibleRecipientsAttributes = $event"
                  @update:size="itemHistoryTemp.size = $event"
                  @remove="removeVendingHistoryTempByValue(itemHistoryTemp.value)"
                />
              </div>
            </transition-group>
          </v-card>
          <!-- Item end -->
        </v-card-text>
      </v-card>
      <v-app-bar
        bottom
        outlined
        height="76"
        fixed
      >
        <div class="d-flex flex-column align-center w-full h-full px-5">
          <v-spacer />
          <v-btn
            color="primary"
            block
            :ripple="false"
            :loading="isCreating"
            :disabled="!isValidContents || isCreating"
            @click="createTable"
          >
            作成
          </v-btn>
          <v-spacer />
        </div>
      </v-app-bar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  inject,
  provide,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiDotsGrid,
  mdiPlus,
  mdiViewGridPlus,
  mdiCashMarker,
  mdiAccountOutline,
  mdiAccountCircleOutline,
} from '@mdi/js'
import { cloneDeep } from 'lodash'
import useVendingHistory from '@/views/composable/useVendingHistory'
import useReferral from '@/views/composable/useReferral'
import usePreventBackgroundScrolling from '@/views/composable/usePreventBackgroundScrolling'
import TableApi from '@/api/waiter/Table'
import TableName from '@/views/components/table/TableName.vue'
import NumberCounter from '@/views/components/util/NumberCounter.vue'
import ReferralCard from '@/views/components/table/ReferralCard.vue'
import OrderCard from '@/views/components/table/OrderCard/OrderCard.vue'
import CustomerTableTagging from './CustomerTableTagging.vue'

export default {
  components: {
    CustomerTableTagging,
    TableName,
    NumberCounter,
    ReferralCard,
    OrderCard,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    hallId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableNumber: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    referrals: {
      type: Array,
      default: () => [],
    },
    nominations: {
      type: Array,
      default: () => [],
    },
    courses: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const customerCount = ref(1)
    const isCreating = ref(false)

    const users = inject('users', [])

    const {
      referringHistoriesTemp,
      addReferringHistoryTemp,
      removeReferringHistoryTemp,
      updateReferringHistoryTemp,
      resetReferringHistoriesTemp,
      isValidToSubmit: isValidReferringHistoriesToSubmit,
    } = useReferral()

    // NOTE: useVendingHistoryにreferringHistoriesTempをinjectするため
    // provideしてからuseVendingHistory()しないとinjectされない（順番に依存している）
    provide('referringHistoriesTemp', referringHistoriesTemp)
    const {
      vendingHistoriesTemp,
      nominationHistoriesTemp,
      courseHistoriesTemp,
      itemHistoriesTemp,
      vendingHistoriesToSubmit,
      addVendingHistoryTemp,
      removeVendingHistoryTempByValue,
      resetVendingHistoriesTemp,
      isValidToSubmit: isValidVendingHistoriesToSubmit,
      setVendingContributionsAttributes,
    } = useVendingHistory()

    const customerTableTaggings = ref([])

    const { target: drawer } = usePreventBackgroundScrolling()

    const isValidContents = computed(() => {
      return isValidReferringHistoriesToSubmit.value && isValidVendingHistoriesToSubmit.value
    })

    const setCustomerTableTaggings = tags => {
      customerTableTaggings.value = tags
    }

    // NOTE: ドロワーが開かれた時に
    // セット付きの卓であればcourseHistoryTempをつくる
    watch(
      () => props.isVisible,
      (newVal, _) => {
        if (!newVal) return

        if (courseHistoriesTemp.value.length < 1 && props.courses[0]?.id) {
          addVendingHistoryTemp({
            vendibleId: props.courses[0].id,
            vendibleType: 'Course',
            sellingPrice: props.courses[0].attributes.sellingPrice,
          })
        }
      },
      { immediate: true },
    )

    // NOTE: courseHistoryTempが一個だけの時はsizeは客数と一致させる
    watch(
      [
        () => customerCount.value,
        () => courseHistoriesTemp.value.length,
      ],
      (
        [newCount, newLen],
        [_prevCount, _prevLen],
      ) => {
        if (newLen !== 1) return

        // eslint-disable-next-line no-param-reassign
        courseHistoriesTemp.value[0].size = newCount
      },
    )

    const reset = () => {
      customerCount.value = 1
      resetReferringHistoriesTemp()
      resetVendingHistoriesTemp()
    }

    const close = () => {
      reset()
      emit('close')
    }

    const createTable = async () => {
      isCreating.value = true

      if (isValidContents.value) {
        const res = await TableApi.createTable({
          hallId: props.hallId,
          tableNumber: props.tableNumber,
          customerCount: customerCount.value,
          referringHistories: referringHistoriesTemp.value,
          vendingHistories: vendingHistoriesToSubmit.value,
          customerTableTaggings: customerTableTaggings.value,
        })

        if (res?.data?.data) emit('created', res.data.data)

        vm.$toast.success(`${[props.tablePrefix, props.tableNumber, props.tableSuffix].join('')}を作成しました`)

        close()
      }
      isCreating.value = false
    }

    // NOTE: 販売貢献人をvendingHistoryTempに自動セットするwatch
    watch(
      [
        () => cloneDeep(vendingHistoriesTemp.value),
        () => cloneDeep(referringHistoriesTemp.value),
      ],
      (
        [_new1, _new2],
        [_prev1, _prev2],
      ) => {
        setVendingContributionsAttributes({
          targetVendingHistoriesTemp: vendingHistoriesTemp.value,
          targetReferringHistoriesTemp: referringHistoriesTemp.value,
        })
      },
    )

    return {
      // data
      users,
      isCreating,
      customerCount,
      referringHistoriesTemp,
      customerTableTaggings,
      drawer,

      // computed
      nominationHistoriesTemp,
      courseHistoriesTemp,
      itemHistoriesTemp,
      isValidContents,
      isValidReferringHistoriesToSubmit,
      isValidVendingHistoriesToSubmit,

      // methods
      addReferringHistoryTemp,
      removeReferringHistoryTemp,
      updateReferringHistoryTemp,
      addVendingHistoryTemp,
      removeVendingHistoryTempByValue,
      createTable,
      close,
      closeConditional: () => !!document.querySelector('.v-overlay--active') && props.isVisible,
      include: () => [
        ...document.querySelectorAll('.v-navigation-drawer--open'),
        ...document.querySelectorAll('.v-menu__content'),
        ...document.querySelectorAll('.v-dialog__content'),
        ...document.querySelectorAll('.Vue-Toastification__toast'),
      ],
      setCustomerTableTaggings,

      icons: {
        mdiDotsGrid,
        mdiPlus,
        mdiViewGridPlus,
        mdiCashMarker,
        mdiAccountOutline,
        mdiAccountCircleOutline,
      },
    }
  },
}
</script>
